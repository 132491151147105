<template>
    <div :class="classes"></div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'

const props = defineProps({
    blok: {
        type: Object as PropType<Record<string, any>>,
        required: true,
    },
})

const allowedClasses = [
    'mt-1',
    'mt-2',
    'mt-3',
    'mt-4',
    'mt-5',
    'mt-6',
    'mt-7',
    'mt-8',
    'mt-9',
    'mt-10',
]

const baseMargin = computed(() => {
    const margin = props.blok.margin

    if (!allowedClasses.includes(margin)) {
        return null
    }

    let sizeClass

    switch (margin as SpaceClass) {
        case SpaceClass.MT_1:
            sizeClass = 'mt-1'
            break
        case SpaceClass.MT_2:
            sizeClass = 'mt-2'
            break
        case SpaceClass.MT_3:
            sizeClass = 'mt-3'
            break
        case SpaceClass.MT_4:
            sizeClass = 'mt-4'
            break
        case SpaceClass.MT_5:
            sizeClass = 'mt-5'
            break
        case SpaceClass.MT_6:
            sizeClass = 'mt-6'
            break
        case SpaceClass.MT_7:
            sizeClass = 'mt-7'
            break
        case SpaceClass.MT_8:
            sizeClass = 'mt-8'
            break
        case SpaceClass.MT_9:
            sizeClass = 'mt-9'
            break
        case SpaceClass.MT_10:
            sizeClass = 'mt-10'
            break
    }

    return sizeClass
})

const smMargin = computed(() => {
    const margin = props.blok.sm_margin || null

    if (margin == null || !allowedClasses.includes(margin)) {
        return null
    }

    let sizeClass

    switch (margin as SpaceClass) {
        case SpaceClass.MT_1:
            sizeClass = 'sm:mt-1'
            break
        case SpaceClass.MT_2:
            sizeClass = 'sm:mt-2'
            break
        case SpaceClass.MT_3:
            sizeClass = 'sm:mt-3'
            break
        case SpaceClass.MT_4:
            sizeClass = 'sm:mt-4'
            break
        case SpaceClass.MT_5:
            sizeClass = 'sm:mt-5'
            break
        case SpaceClass.MT_6:
            sizeClass = 'sm:mt-6'
            break
        case SpaceClass.MT_7:
            sizeClass = 'sm:mt-7'
            break
        case SpaceClass.MT_8:
            sizeClass = 'sm:mt-8'
            break
        case SpaceClass.MT_9:
            sizeClass = 'sm:mt-9'
            break
        case SpaceClass.MT_10:
            sizeClass = 'sm:mt-10'
            break
    }

    return sizeClass
})

const lgMargin = computed(() => {
    const margin = props.blok.lg_margin || null

    if (margin == null || !allowedClasses.includes(margin)) {
        return null
    }

    let sizeClass

    switch (margin as SpaceClass) {
        case SpaceClass.MT_1:
            sizeClass = 'lg:mt-1'
            break
        case SpaceClass.MT_2:
            sizeClass = 'lg:mt-2'
            break
        case SpaceClass.MT_3:
            sizeClass = 'lg:mt-3'
            break
        case SpaceClass.MT_4:
            sizeClass = 'lg:mt-4'
            break
        case SpaceClass.MT_5:
            sizeClass = 'lg:mt-5'
            break
        case SpaceClass.MT_6:
            sizeClass = 'lg:mt-6'
            break
        case SpaceClass.MT_7:
            sizeClass = 'lg:mt-7'
            break
        case SpaceClass.MT_8:
            sizeClass = 'lg:mt-8'
            break
        case SpaceClass.MT_9:
            sizeClass = 'lg:mt-9'
            break
        case SpaceClass.MT_10:
            sizeClass = 'lg:mt-10'
            break
    }

    return sizeClass
})

const xlMargin = computed(() => {
    const margin = props.blok.xl_margin || null

    if (margin == null || !allowedClasses.includes(margin)) {
        return null
    }

    let sizeClass

    switch (margin as SpaceClass) {
        case SpaceClass.MT_1:
            sizeClass = 'xl:mt-1'
            break
        case SpaceClass.MT_2:
            sizeClass = 'xl:mt-2'
            break
        case SpaceClass.MT_3:
            sizeClass = 'xl:mt-3'
            break
        case SpaceClass.MT_4:
            sizeClass = 'xl:mt-4'
            break
        case SpaceClass.MT_5:
            sizeClass = 'xl:mt-5'
            break
        case SpaceClass.MT_6:
            sizeClass = 'xl:mt-6'
            break
        case SpaceClass.MT_7:
            sizeClass = 'xl:mt-7'
            break
        case SpaceClass.MT_8:
            sizeClass = 'xl:mt-8'
            break
        case SpaceClass.MT_9:
            sizeClass = 'xl:mt-9'
            break
        case SpaceClass.MT_10:
            sizeClass = 'xl:mt-10'
            break
    }

    return sizeClass
})

const classes = computed(() => {
    const returnClassArr = []

    if (baseMargin.value) {
        returnClassArr.push(baseMargin.value)
    }

    if (smMargin.value) {
        returnClassArr.push(smMargin.value)
    }

    if (lgMargin.value) {
        returnClassArr.push(lgMargin.value)
    }

    if (xlMargin.value) {
        returnClassArr.push(xlMargin.value)
    }

    return returnClassArr.length > 0 ? returnClassArr.join(' ') : 'mt-6'
})

enum SpaceClass {
    MT_1 = 'mt-1',
    MT_2 = 'mt-2',
    MT_3 = 'mt-3',
    MT_4 = 'mt-4',
    MT_5 = 'mt-5',
    MT_6 = 'mt-6',
    MT_7 = 'mt-7',
    MT_8 = 'mt-8',
    MT_9 = 'mt-9',
    MT_10 = 'mt-10',
}
</script>
